@import url('https://fonts.googleapis.com/css?family=Pacifico');

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}

.accordion-body {
  --bs-accordion-body-padding-y: 0.5rem;
}

.alert {
  --bs-alert-padding-x: 0.375rem;
  --bs-alert-padding-y: 0.375rem;
  --bs-alert-margin-bottom: 0.275rem;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.list-group {
  --bs-list-group-active-bg: #5C80BC;
}

.animate-transform {
  transition: transform 500ms;
}

.form-switch .form-check-input:checked {
  background-color: #30D158;
  border-color: #30D158;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

.container {
  height: 100%;
}

.scrollable {
  overflow-y: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.fade-in {
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.main-card {
  border: none !important;
}

.main-card {
  background-color: #5C80BC;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border: none !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
}

.container {
  --bs-gutter-x: 0.375rem;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  font-size: 25px;
}

.btn {
  box-shadow: rgba(0 0 0) 0px 3px 5px -1px
}

body {
  overflow-anchor: none;
}

.navbar-nav {
  --bs-nav-link-padding-y: 0rem;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: #5C80BC !important;
}

.bg-light {
  --bs-light-rgb: 255,255,255;
}

.content {
  margin: 20px 0;
}

.preview {
  max-width: 200px;
}

.img {
  border-radius: 50%;
}

.row {
  --bs-gutter-x: 1.375rem;
}

.react-datepicker-popper {
  z-index: 999 !important
}